import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    ogeeId: 5,
    tokenName: 'DINO',
    tokenAddress: '',
    stakingTokenName: QuoteToken.DINO,
    stakingTokenAddress: '0x137FE1500262ff55DDf611673351DF9dCeB89e72',
    contractAddress: {
      128: '0x1f129562C20d7d1471F05d6397BacD24Df986089',
      256: '',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '0.1',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
]

export default pools
