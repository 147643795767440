export default {
  oyt: {
    265: '',
    128: '0x137FE1500262ff55DDf611673351DF9dCeB89e72',
  },
  oge: {
    265: '',
    128: '0x137FE1500262ff55DDf611673351DF9dCeB89e72',
  },
  masterChef: {
    265: '',
    128: '0x1f129562C20d7d1471F05d6397BacD24Df986089',
  },
  ogeeChef: {
    265: '',
    128: '0x1f129562C20d7d1471F05d6397BacD24Df986089',
  },
  wht: {
    265: '',
    128: '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
  },
  lottery: {
    265: '',
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
  },
  lotteryNFT: {
    265: '',
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
  },
  mulltiCall: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
  husd: {
    128: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047',
    265: '',
  },
  usdt: {
    128: '0xa71EdC38d189767582C38A3145b5873052c3e47a',
    265: '',
     },
  usdc: {
      128: '0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B',
      265: '',
      },
  eth: {
    128: '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
    265: '',
  },
  ifo: {
    128: '0xf27EC4Df257E840C553566831A694E7eBb501271',
    265: '',
  },
  ogeeYieldProfile: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
  ogeeYieldRabbits: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
  bunnyFactory: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
  claimRefund: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
  pointCenterIfo: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
  bunnySpecial: {
    128: '0x713e35713757cC28F96a0Eb2D95cf984c68B2471',
    265: '',
  },
}
