import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 3,
    lpSymbol: 'DINO-HUSD LP',
    lpAddresses: {
      256: '',
      128: '0x294990bde531ad66f9e7be693e348e6145290a2a',//
    },
    tokenSymbol: 'DINO',
    tokenAddresses: {
      256: '',
      128: '0x137FE1500262ff55DDf611673351DF9dCeB89e72',
    },
    quoteTokenSymbol: QuoteToken.HUSD,
    quoteTokenAdresses: contracts.husd,
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'DINO-HT LP',
    lpAddresses: {
      256: '',
      128: '0x3b9c873d4ad22daf66a945b223d4ff2b41ac5af9',
    },
    tokenSymbol: 'DINO',
    tokenAddresses: {
      256: '',
      128: '0x137FE1500262ff55DDf611673351DF9dCeB89e72',
    },
    quoteTokenSymbol: QuoteToken.HT,
    quoteTokenAdresses: contracts.wht,
  },
  {
    pid: 2,
    risk: 3,
    lpSymbol: 'DINO-USDT LP',
    lpAddresses: {
      256: '',
      128: '0xe0ba2a52498ad2cd5f8263925b14f2e536a13c53',
    },
    tokenSymbol: 'DINO',
    tokenAddresses: {
      256: '',
      128: '0x137FE1500262ff55DDf611673351DF9dCeB89e72',
    },
    quoteTokenSymbol: QuoteToken.HT,
    quoteTokenAdresses: contracts.wht,
  },
  {
    pid: 3,
    risk: 3,
    lpSymbol: 'HT-USDT LP',
    lpAddresses: {
      256: '',
      128: '0x0061cdaf59f42f6eb7483ac0b7d307de0b378d79',
    },
    tokenSymbol: 'HT',
    tokenAddresses: {
      256: '',
      128: '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 4,
    risk: 3,
    lpSymbol: 'HUSD-HT LP',
    lpAddresses: {
      256: '',
      128: '0x916f41f062878e603cec60fc79ed50e3207a1713',
    },
    tokenSymbol: 'HUSD',
    tokenAddresses: {
      256: '',
      128: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047',
    },
    quoteTokenSymbol: QuoteToken.HT,
    quoteTokenAdresses: contracts.wht,
  },
]

export default farms